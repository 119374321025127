import styled, {css} from 'styled-components';
 
export const TitleWrapper = styled.div `
  line-height: 1;
  margin: 0 auto;
  ${props => props.titleAlign === 'left' && css `
    text-align: ${props => props.titleAlign};
    padding: 0;
  `};

  ${props => props.titleAlign === 'center' && css `
    text-align: ${props => props.titleAlign};
    padding: 0 15px;
  `};
`;

