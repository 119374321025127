// @ts-nocheck 

module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || '4q0zkag0',
    dataset: process.env.GATSBY_SANITY_DATASET || 'production'
  },
  tracking: {
    gtm: process.env.GATSBY_GTM_TRACKING_ID || 'GTM-TPK5TWX'
  },
  hs: {
    id: process.env.GATSBY_HUBSPOT_TRACKING_ID || '5468172',
    apikey: process.env.GATSBY_HUBSPOT_HAPIKEY || '395e6155-8628-46d7-b9e6-0054b3a7485c',
    formguid: process.env.GATSBY_HUBSPOT_FORM_GUID || '1de63b7b-e1bc-4c5c-989d-e8e9fbfae091',
  }
}